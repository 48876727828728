import { ServerResponse } from 'http'
import {
  FSXAApi, FSXARemoteApi, NavigationData,
} from 'fsxa-api'
import isExternalURL from '../shared/fsxa/services/ExternalLinkService'
import { TRequestHandler } from '../shared/general/types/TRequestHandler'
import { TRequest } from '../shared/general/types/TRequest'
import { localeReplacer } from '../shared/general/services/LanguageService'
import { Logger } from '../shared/general/logger/Logger'
import { middleware, redirects } from '../shared/general/logger/LogKey'
import { projectProperties, PROPERTIES_KEY } from '../serverMiddleware/GlobalCache'

const redirectsMemo = new Map<string, string>()

setInterval(() => {
  redirectsMemo.clear()
}, 1000 * 60 * 10) // clear every 10 minutes to reduce ram usage

interface IRequestUrl {
  protocol : string
  domain : string
  host : string
  fullUrl : string
  completeUrl : string
  path : string
  params : string
}

const getRequestUrl = (request : TRequest) : IRequestUrl => {
  const protocol : string = process.env.NODE_ENV === 'production' ? 'https' : 'http'
  const domain : string = request.headers.host || ''
  const host : string = domain ? `${protocol}://${domain}` : ''
  const [path, params] : string[] = request.url.split('?')
  return {
    protocol,
    domain,
    host,
    fullUrl: host + request.url,
    completeUrl: `${host}${request.url}${params ? `?${params}` : ''}`,
    path,
    params: params ? `?${params}` : '',
  }
}

export const getIndexPageUrl = async (api : FSXAApi, _locale : string = '') : Promise<string> => {
  try {
    const locale : string = _locale || process.env.FSXA_LOCALE || ''
    if (locale.length !== 5) return ''

    const navigationResponse : NavigationData | null = await api.fetchNavigation({ locale: localeReplacer(locale), initialPath: '/' })
    return navigationResponse?.pages?.index || ''
  } catch (error) {
    Logger.error(middleware, `RedirectsHandler | getIndexPageUrl | locale: ${_locale} |`, error)
    return ''
  }
}

const getRedirects = async () : Promise<[string, string][]> => {
  try {
    return (await fetch('http://localhost:3000/api/redirects')).json()
  } catch (error) {
    Logger.error(middleware, 'RedirectsHandler | unable to fetch redirects from /api/redirects |', error)
    return []
  }
}

const checkRedirectList = async (api : FSXARemoteApi, requestUrl : IRequestUrl) : Promise<string> => {
  const redirectData : [string, string][] = await getRedirects()
  try {
    return redirectData.map(([from, to]) : string => {
      // Select content between trailing slashes (to extract this content later)
      const trailingSlashesRegex : RegExp = /^\/?(.*?)\/?$/g

      // Select multiple following slashes
      const multiSlashes : RegExp = /\/+/g

      const fromClean : string = from.replace(multiSlashes, '/').replace(trailingSlashesRegex, '$1')
      const toClean : string = to.replace(multiSlashes, '/').replace(trailingSlashesRegex, '$1')
      const pathClean : string = requestUrl.path.replace(multiSlashes, '/').replace(trailingSlashesRegex, '$1')

      // Guard functions. Order is important and intentional!
      if (isExternalURL(fromClean)) {
        const fromNoProtocol : string = fromClean.replace(/https?:\//, '')

        // we need to take into account that 'fromNoProtocol' does not contain any ending slash
        const requestTarget : string[] = [requestUrl.domain, (pathClean || requestUrl.params) ? '/' : '', pathClean, requestUrl.params]
        if (fromNoProtocol === requestTarget.join('')) return to
      }
      if (toClean === pathClean) return ''
      if (fromClean === `${pathClean}${requestUrl.params}`) return to
      if (fromClean === `${pathClean}`) return to
      if (!fromClean.includes('...')) return ''

      const [start, end] = fromClean.split('...')

      // Checks if there are other characters between start and end
      // as /asd/.../asdf should match asd/bla/asdf but not asd/asdf
      const middleRegex : RegExp = new RegExp(`^${start}(.+?)${end}$`)

      const wildcardMatches = {
        beginning: start === '' && pathClean.endsWith(end),
        end: end === '' && pathClean.startsWith(start),
        middle: middleRegex.test(pathClean),
      }

      const matches : boolean = Object.values(wildcardMatches).some((match : boolean) => match)
      if (!matches) return ''

      // Build redirect URL
      if (toClean.includes('...')) {
        // Regex to select the wildcard and the maybe existing trailing slash
        const wildCardRegex : RegExp = /\.{3}\/?/
        const fromNoWildcard : string = fromClean.replace(wildCardRegex, '')
        const toNoWildCard : string = toClean.replace(wildCardRegex, '')
        return pathClean.replace(fromNoWildcard, toNoWildCard)
      }

      return to
    }).filter((e : string | undefined) => !!e)?.[0] || ''
  } catch (error) {
    Logger.error(middleware, `RedirectsHandler | getUrlForRedirect | path: ${requestUrl.path} |`, error)
    return ''
  }
}

const isFileUrl = (urlPath : string) : boolean => {
  try {
    const validExtensions : string[] = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'json']

    return new RegExp(`\\.(${validExtensions.join('|')})$`, 'i').test(urlPath)
  } catch (error) {
    Logger.error(middleware, `RedirectsHandler | isFileUrl | url: ${urlPath} |`, error)
    return false
  }
}

const checkMediaRedirect = (fsxaApi : FSXARemoteApi, url : string) : string => {
  const regex : RegExp = /(\/media\/.*)/
  const match : RegExpMatchArray | null = url.match(regex)

  return match?.[1] ? `${process.env.SMART_SEARCH_MEDIA_HOST}/${fsxaApi.projectID}${match[1]}` : ''
}

const useRedirect = (response : ServerResponse, from : string) => (to : string) => {
  // if both are the same, it is no redirect
  if (from === to) return

  if (!redirectsMemo.has(from)) {
    Logger.verbose(middleware, redirects, `New memo ${from} to ${to}`)
    redirectsMemo.set(from, to)
  }

  response.writeHead(301, { Location: to })
  response.end()
}

const handler : TRequestHandler = async (
  fsxaApi : FSXARemoteApi,
  request : TRequest,
  response : ServerResponse,
) : Promise<boolean> => {
  const requestUrl : IRequestUrl = getRequestUrl(request)
  const doRedirect = useRedirect(response, requestUrl.completeUrl)

  if (redirectsMemo.has(requestUrl.completeUrl)) {
    const locationTo = redirectsMemo.get(requestUrl.completeUrl)!
    Logger.verbose(middleware, redirects, `From memo ${requestUrl.completeUrl} to ${locationTo}`)
    doRedirect(locationTo)
    return locationTo !== requestUrl.completeUrl
  }

  let redirectUrl = await checkRedirectList(fsxaApi, requestUrl)
  Logger.verbose(middleware, redirects, `Fresh ${requestUrl.completeUrl} to ${redirectUrl}`)

  if (redirectUrl) {
    if (isExternalURL(redirectUrl)) {
      // immediately do the redirect and return, because an external URL doesn't need to be further checked
      Logger.verbose(middleware, redirects, `Redirecting from ${requestUrl.fullUrl} to external URL ${redirectUrl}`)
      doRedirect(redirectUrl)
      return true
    }
    Logger.verbose(middleware, redirects, `Redirect found in list from ${requestUrl.fullUrl} to ${redirectUrl}`)
    redirectUrl = redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`
  }

  // check media redirects
  const mediaRedirectUrl : string = checkMediaRedirect(fsxaApi, redirectUrl || requestUrl.path)

  if (mediaRedirectUrl) {
    // immediately do the redirect and return, because a media redirect doesn't need to be further checked
    Logger.verbose(middleware, redirects, `Redirecting to media CDN from ${requestUrl.fullUrl} to ${mediaRedirectUrl}`)
    doRedirect(mediaRedirectUrl)
    return true
  }

  // check homepage redirect
  if ((!redirectUrl && (requestUrl.path === '/' || requestUrl.path === '')) || redirectUrl === '/') {
    redirectUrl = await getIndexPageUrl(fsxaApi) || ''
    Logger.verbose(middleware, redirects, `Redirect to homepage ${redirectUrl}`)
  }

  // check URL ending with '/'
  if (redirectUrl) {
    const [redirectUrlPath, redirectUrlParams] : string[] = redirectUrl.split('?')
    if (!isFileUrl(redirectUrlPath) && !redirectUrlPath.endsWith('/')) {
      redirectUrl = `${redirectUrlPath}/${redirectUrlParams ? `?${redirectUrlParams}` : ''}`
    }
  } else if (!isFileUrl(requestUrl.path) && !requestUrl.path.endsWith('/')) {
    Logger.verbose(middleware, redirects, 'Redirect to ending / because the url was not ending with /')
    redirectUrl = `${requestUrl.path}/${requestUrl.params}`
  }

  // check redirect to main domain of the project
  // except for localhost and preview
  const isLocalOrPreview = requestUrl.host.includes('localhost') || requestUrl.host.includes('preview.azurewebsites.net')
  const projectUrl = isLocalOrPreview ? requestUrl.domain : ((projectProperties.get(PROPERTIES_KEY)?.data?.ps_url as string | undefined) || '')
  const baseUrl = projectUrl ? `${requestUrl.protocol}://${projectUrl}` : ''

  redirectUrl = redirectUrl
    ? `${baseUrl}${redirectUrl}`
    : `${baseUrl}${requestUrl.path}${requestUrl.params}`

  if (redirectUrl && redirectUrl !== requestUrl.fullUrl) {
    Logger.verbose(middleware, redirects, `Redirecting from ${requestUrl.fullUrl} to ${redirectUrl}`)
    doRedirect(redirectUrl)
    return true
  }

  // if no redirect happens, still save to memo to save performance
  redirectsMemo.set(requestUrl.completeUrl, requestUrl.completeUrl)
  return false
}

export default handler
